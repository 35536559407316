<template>
  <div v-if="home">
    <v-container fluid class="pt-0 px-0">
      <v-carousel hide-delimiter-background height="725px">
        <v-carousel-item >
          <v-img v-if="home.header_image" style="margin:auto;" :src="'https://directus.offenestadt.info/assets/' + home.header_image.id" max-height="725px">
            <v-container class="white--text fill-height">
              <v-row align="center" no-gutters>
                <v-col cols="12">
                  <ColorCircle color="white" />
                </v-col>
                <v-col cols="12" md="6">
                  <div v-html="home.header_content" style="clear:both;" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-img class="my-xs-1 my-sm-4 display-inline-block" style="margin:auto;" src="../assets/logos/CodeForHamburg.svg" max-height="200px" width="200px" contain />
                  <!--<v-img class="my-xs-1 my-sm-4 display-inline-block" style="margin:auto;" src="../assets/logos/Logo_Koerber-Stiftung_weiss.png" width="200px" contain />-->
                </v-col>
                <v-col cols="12" class="pt-4">
                  <v-btn color="#A1C2E6" href="https://www.eventbrite.de/e/young-open-city-forum-young-citizens-future-cities-tickets-881807980407" target="_blank" class="white--text ma-1">Jetzt teilnehmen</v-btn>
                  <!--<v-btn color="#A1C2E6"  href="/Programm%20FOS%202021.pdf" target="_blank" class="white--text ma-1">Programm öffnen</v-btn>-->
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-container>
   <!--
    <v-container class="pa-0 my-8" id="livestream">
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <ColorCircle color="white" />
        </v-col>
        <v-col cols="12" md="6">
          <h2 class="white--text">Livestream</h2>
        </v-col>
      </v-row>
      <iframe style="display:block; margin: 20px auto" src="//rrr.sz.xlcdn.com/?account=koerber-stiftung&file=kf-3-double-intake&type=live&service=wowza&poster=StreamZilla-Poster_NEU-2_720x405.png&output=player" width="100%" height="500px" frameborder="0" seamless allow="autoplay; fullscreen" allowfullscreen="true" webkitAllowFullScreen="true"></iframe>
    </v-container>

    <v-container v-show="displayZoomButtons" style="text-align:center">
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn href="https://us06web.zoom.us/j/85427637835?pwd=aksyeHBIbkEzS1Y4MEVDcDRhbjJxUT09" target="_blank" class="text-xs-h6 text-sm-h6 md-h4 ma-4 pa-8" color="yellow">Zur Zoom Session Raum A</v-btn>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn href="https://us06web.zoom.us/j/88381080388?pwd=NldkWXJyeVVBa3lwOW1CTU9HdGErZz09" target="_blank" class="text-xs-h6 text-sm-h6 md-h4 ma-4 pa-8" color="yellow">Zur Zoom Session Raum B</v-btn>
        </v-col>
      </v-row>
    </v-container>
-->
    <!--<v-container class="pa-0 my-8">
      <TimelineCard />
    </v-container>-->

    <v-container v-if="home" class="pa-0 my-8">
      <v-row justify="space-around" class="ma-0">
        <v-col cols="12" sm="12" md="6">
          <ContentCard card-color="transparent" circle-color="yellow" font-color="white" :headline="home.box_1_headline" :content="home.box_1_content"/>
          <div style="width:200px;margin: auto">
            <!--<v-btn color="#A1C2E6"  href="/Programm%20FOS%202021.pdf" target="_blank" class="white--text">Programm öffnen</v-btn>-->
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-img class="rounded" v-if="home.box_1_image" style="margin:auto;" :src="'https://directus.offenestadt.info/assets/' + home.box_1_image.id" :title="home.box_1_image.title" max-height="725px" />
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import ContentCard from '@/components/ContentCard.vue'
import EventCard from '@/components/EventCard.vue'
import TimelineCard from '@/components/TimelineCard.vue'
import ColorCircle from '@/components/ColorCircle.vue'

export default {
  name: 'Home',
  components: {
    ContentCard,
    EventCard,
    TimelineCard,
    ColorCircle
  },
  data: () => ({
    home:null,
    event: null,
    displayZoomButtons: false
  }),
  async created() {
    let temp = await this.$client.items("home").readMany({ fields: ['*.*']})
    this.home = temp.data

    temp = await this.$client.items("events").readMany({ fields: ['*.*']})
    this.event = temp.data[0]

    var now = new Date();
    var secondsTillZoom = new Date(2021,10,5,13,15,0).getTime() - now.getTime();

    if (secondsTillZoom  <= 0) this.displayZoomButtons = true
    else {
      setTimeout(() => {this.displayZoomButtons = true}, secondsTillZoom);
    }
  }
}
</script>

<style lang="scss">

.display-inline-block {
  display: inline-block
}

</style>
